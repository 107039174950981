import React, {useMemo} from 'react';
import TopArtistsTable from "./TopArtistsTable";
import {Box, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TopCollectorsTable from "./TopCollectorsTable";
import TopSalesByDay from "../../profile/reports/TopSalesByDay";
import {useNavigate, useParams} from "react-router-dom";
import MarketVolumes from "../../profile/reports/MarketVolumes";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3, paddingLeft: 0, paddingRight: 0}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const homeTabs = ['artists', 'collectors', 'topSales', 'marketVolumes'];

function Home() {
    const {tabName} = useParams();

    const navigate = useNavigate();

    const tabId: number = useMemo(() => {
        if (tabName == null) return 0;
        return homeTabs.indexOf(tabName) || 0;
    }, [tabName]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(`/tab/${homeTabs[newValue]}`)
    };

    return (<>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={tabId} onChange={handleChange} aria-label="nft view tabs" variant="scrollable">
                    <Tab label={<Typography variant="h4">Top Artists</Typography>} {...a11yProps(0)} />
                    <Tab label={<Typography variant="h4">Top Collectors</Typography>} {...a11yProps(1)} />
                    <Tab label={<Typography variant="h4">Top Tokens</Typography>} {...a11yProps(2)} />
                    <Tab label={<Typography variant="h4">Market Volumes</Typography>} {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={tabId} index={0}>
                <TopArtistsTable/>
            </TabPanel>
            <TabPanel value={tabId} index={1}>
                <TopCollectorsTable/>
            </TabPanel>
            <TabPanel value={tabId} index={2}>
                <TopSalesByDay/>
            </TabPanel>
            <TabPanel value={tabId} index={3}>
                <MarketVolumes/>
            </TabPanel>
        </>
    );
}

export default Home;