import {Bar, BarChart, Brush, CartesianGrid, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import React, {useEffect, useState} from "react";
import {Avatar, Chip, LinearProgress, Typography} from "@mui/material";
import {apiBaseUrl} from "../../../Constants";
import {formatTez, getDomainProfileOrWallet} from "../../common/utils";
import {blue, green} from '@mui/material/colors';
import dayjs, {Dayjs} from "dayjs";

type Datapoint = { date: string, value: number };

interface TopMarketSalesChartProps {
    proportionDesktop: number,
    selectedDay?: Dayjs | null,
    onSelectedDayChange?: (newDay: Dayjs | null) => void;
}

function TopMarketSalesChart(props: TopMarketSalesChartProps) {
    const [historyData, setHistoryData] = useState<Datapoint[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const today = (new Date()).toISOString().slice(0, 10);
        const yearAgo = (new Date((new Date()).setFullYear((new Date()).getFullYear() - 1))).toISOString().slice(0, 10);
        setIsLoading(true);
        fetch(`${apiBaseUrl}timeseries/top/${yearAgo}/${today}`)
            .then(response => response.json())
            .then(history => {
                setHistoryData(history.filter((point?: Datapoint) => point));
                setIsLoading(false);
            });
    }, [])

    const renderCustomYTick = (payload: any) => {
        const {x, y, width} = payload;
        return <text x={x - width / 2} y={y + 10} fill="#666" textAnchor="middle"
                     dy={-6}>{`${formatTez(payload.payload.value)}`}</text>;
    };

    function CustomTooltip(pl: any) {
        const {active, payload} = pl;

        if (active && payload?.[0]?.payload != null) {
            const pld = payload[0].payload;
            const artist = pld.artists[0]
            return (<>
                <Typography sx={{paddingLeft: 1}}> {`${pld.date}`}</Typography>
                <Chip label={`${formatTez(pld.value)}`} color="primary" variant="outlined"
                      avatar={<Avatar> ꜩ </Avatar>}/>
                {' '}
                <img
                    alt="avatar"
                    height={50}
                    src={pld.thumbnail_url}
                    loading="lazy"
                />
                <Typography sx={{}}>
                    {pld.title}
                    {' '}by{' '}
                    {getDomainProfileOrWallet(artist)}
                </Typography>
                <Typography>
                    {getDomainProfileOrWallet(pld.seller)}
                    {' -> '}
                    {getDomainProfileOrWallet(pld.buyer)}
                </Typography>
            </>);
        }

        return null;
    }

    function onChartClicked(data: any) {
        if (data?.activeLabel && props.onSelectedDayChange) {
            props.onSelectedDayChange(dayjs(data.activeLabel))
        }
    }

    if (isLoading) return (<div style={{
        position: 'relative',
        width: '100%',
        paddingBottom: '250px'
    }}>
        {/*this is a hack to make it responsive*/}
        <div
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,

            }}
        ><LinearProgress/>
        </div>
    </div>)

    return (
        <ResponsiveContainer height={300}>
            <BarChart
                data={historyData}
                margin={{
                    top: 5,
                    left: 20,
                    bottom: 5,
                }}
                onClick={onChartClicked}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date"/>
                <YAxis dataKey="value" tick={renderCustomYTick}
                       domain={([dataMin, dataMax]) => {
                           return [Math.floor(dataMin - dataMax / 10), Math.ceil(dataMax + dataMax / 10)];
                       }}/>
                <Tooltip content={<CustomTooltip/>}/>
                {props.selectedDay != null &&
                <ReferenceLine x={props.selectedDay.format('YYYY-MM-DD')} stroke="red"
                               label={`${props.selectedDay!.format('YYYY-MM-DD')}`}/>}
                <Bar dataKey="value" fill={green.A700}/>
                <Brush dataKey="date" height={30} stroke={blue["700"]}/>
            </BarChart>
        </ResponsiveContainer>
    );
}

export default TopMarketSalesChart;