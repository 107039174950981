import {Container, Grid, LinearProgress, Pagination, PaginationItem} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Order} from "../../common/Constants";
import {apiBaseUrl} from "../../../Constants";
import NFTCard from "./NFTCard";
import {ApiResponse} from "../../../models/ApiResponse";
import {Nft} from "../../../models/Token";
import {Link, useLocation} from "react-router-dom";


export default function NFTCards(props: { userId?: string }) {
    const userId = props.userId;
    const [isLoading, setIsLoading] = useState(false);
    const [isOrderedByPrice, setIsOrderedByPrice] = useState(true);
    const [priceOrder, setPriceOrder] = useState<Order>('desc');
    const [totalRecords, setTotalRecords] = useState(100);
    const [loadedTokens, setLoadedTokens] = useState<Nft[]>([]);
    const rowsPerPage = 8;

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);

    useEffect(() => {
        const priceOrderDirection = priceOrder === 'asc' ? "" : "-";
        const orderBy: string = isOrderedByPrice ? `&order_by=${priceOrderDirection}last_price` : '';

        if (userId != null) {
            setIsLoading(true);
            const offset: string = page === 1 ? '' : `&offset=${(page - 1) * rowsPerPage}`;
            fetch(`${apiBaseUrl}${userId}/nft?limit=${rowsPerPage}${offset}${orderBy}`)
                .then(response => response.json())
                .then((response: ApiResponse<Nft>) => {
                    setLoadedTokens(response.data);
                    setTotalRecords(response.meta.totalRowCount + rowsPerPage);
                    setIsLoading(false);
                });
        }
    }, [page, priceOrder, isOrderedByPrice, userId, rowsPerPage]);

    return (
        <>
            {isLoading && <Container maxWidth="xl">
                {/*for some reason the loader has a padding (?) on the right*/}
                <LinearProgress/>
            </Container>}

            {!isLoading && totalRecords/rowsPerPage > rowsPerPage &&
            <Pagination showFirstButton
                        showLastButton={false}
                        count={Math.floor(totalRecords / rowsPerPage)}
                        page={page}
                        renderItem={(item) => (
                            <PaginationItem
                                component={Link}
                                to={`/profile/${userId}/?page=${item.page}`}
                                {...item}
                            />
                        )}/>}

            <Grid container spacing={2} alignItems="stretch">

                {!isLoading && loadedTokens.map(nft => <NFTCard key={nft.collection + nft.token} nft={nft}/>)}
            </Grid>
        </>);
}