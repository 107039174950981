import {Box, Typography} from "@mui/material";
import React, {useEffect, useMemo} from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NFTCards from "./nft/NFTCards";
import FungiblesTable from "./FungiblesTable";
import CollectedTable from "./CollectedTable";
import CollectorTable from "./CollectorTable";
import {Profile} from "../../models/Profile";
import {useSearchParams} from 'react-router-dom';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, {Dayjs} from "dayjs";
import ArtistDailySales from "./reports/ArtistDailySales";

interface ProfileTabsProps {
    userId?: string;
    profile?: Profile;
    selectedDay: Dayjs | null;
    onSelectedDayChange: (newDay: Dayjs | null) => void;
}

type ProfileTabNames = 'nfts' | 'fractionals' | 'collected' | 'collectors' | 'daily' | null;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3, paddingLeft: 0, paddingRight: 0}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ProfileTabs(props: ProfileTabsProps) {
    const {userId, profile, selectedDay} = props;

    const [tabId, setTabId] = React.useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

    const tab = searchParams.get('tab') as ProfileTabNames;

    const hasNFTs = Boolean(profile?.nft_number);
    const hasFractionals = Boolean(profile?.fractional_number);
    const hasCollectors = Boolean(profile?.nft_value) || Boolean(profile?.fractional_value);
    const hasCollected = Boolean(profile?.collected_token_number);

    const onTabClicked = (_event: React.SyntheticEvent, tab: string) => {
        const newSearchParams = new URLSearchParams({"tab": tab});
        setSearchParams(newSearchParams);
    };

    const {dailyIndex, nftIndex, fractionalsIndex, collectedIndex, collectorsIndex} = useMemo(() => {
        let dailyIndex = 0;
        let nftIndex = 1;
        let fractionalsIndex = 2;
        let collectedIndex = 3;
        let collectorsIndex = 4;

        if (!hasNFTs && !hasFractionals) {
            dailyIndex -= 1;
            nftIndex = -1;
            fractionalsIndex -= 1;
            collectedIndex -= 1;
            collectorsIndex -= 1;
        }
        if (!hasNFTs) {
            nftIndex = -1;
            fractionalsIndex -= 1;
            collectedIndex -= 1;
            collectorsIndex -= 1;
        }
        if (!hasFractionals) {
            fractionalsIndex = -1;
            collectedIndex -= 1;
            collectorsIndex -= 1;
        }
        if (!hasCollected) {
            collectedIndex = -1;
            collectorsIndex -= 1;
        }
        if (!hasCollectors) {
            collectorsIndex = -1;
        }
        return {dailyIndex, nftIndex, fractionalsIndex, collectedIndex, collectorsIndex};
    }, [hasNFTs, hasFractionals, hasCollected, hasCollectors])

    useEffect(function setTabIndex() {
        if (tab != null) {
            props.onSelectedDayChange(null);
            if (tab === 'nfts' && hasNFTs) setTabId(nftIndex);
            if (tab === 'fractionals' && hasFractionals) setTabId(fractionalsIndex);
            if (tab === 'collected' && hasCollected) setTabId(collectedIndex);
            if (tab === 'collectors' && hasCollectors) setTabId(collectorsIndex);
        } else if (selectedDay != null && (hasNFTs || hasFractionals)) {
            setTabId(dailyIndex);
        } else {
            // if there are no NFTs or Fractionals we don't show the date selection tab. Therefore (most probably) collected tab would have index 0
            setTabId((hasNFTs || hasFractionals) ? 1 : 0);
        }
    }, [tab, selectedDay, hasNFTs, hasFractionals, nftIndex, fractionalsIndex, hasCollected, collectedIndex, hasCollectors, collectorsIndex, dailyIndex]);

    return <>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={tabId} aria-label="nft view tabs" variant="scrollable">
                {(hasNFTs || hasFractionals) &&
                <Tab label={
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Transactions on:"
                            value={selectedDay}
                            onChange={props.onSelectedDayChange}
                            format="DD, MMMM YYYY"
                            maxDate={dayjs((new Date()).setDate((new Date()).getDate() - 1))}
                            minDate={dayjs((new Date()).setFullYear((new Date()).getFullYear() - 1))}
                            views={['day']}
                        />
                    </LocalizationProvider>} {...a11yProps(0)}
                />}

                {hasNFTs &&
                <Tab label={`Editions of 1 (${profile?.nft_number})`} {...a11yProps(1)}
                     onClick={(event) => onTabClicked(event, 'nfts')}/>}

                {hasFractionals &&
                <Tab label={`Multiple editions (${profile?.fractional_number})`} {...a11yProps(2)}
                     onClick={(event) => onTabClicked(event, 'fractionals')}/>}

                {hasCollected &&
                <Tab label={`Collected Tokens (${profile?.collected_token_number})`} {...a11yProps(3)}
                     onClick={(event) => onTabClicked(event, 'collected')}/>}

                {hasCollectors &&
                <Tab label={`Collectors (${profile?.collectors_number})`} {...a11yProps(4)}
                     onClick={(event) => onTabClicked(event, 'collectors')}/>}
            </Tabs>
        </Box>
        <TabPanel value={tabId} index={dailyIndex}>
            {selectedDay != null && <ArtistDailySales day={selectedDay} userId={userId!}/>}
        </TabPanel>
        <TabPanel value={tabId} index={nftIndex}>
            {((tab == null && selectedDay == null) || tab === 'nfts') && <NFTCards userId={userId}/>}
        </TabPanel>
        <TabPanel value={tabId} index={fractionalsIndex}>
            <FungiblesTable userId={userId} current_tab={tab!}/>
        </TabPanel>
        <TabPanel value={tabId} index={collectedIndex}>
            <CollectedTable userId={userId} current_tab={tab!}/>
        </TabPanel>
        <TabPanel value={tabId} index={collectorsIndex}>
            <CollectorTable userId={userId} current_tab={tab!}/>
        </TabPanel>
    </>
}
