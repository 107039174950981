import React from "react";
import {SITE_NAME} from "../common/Constants";

function About() {

    return (
        <main>
            <h1>About {SITE_NAME}</h1>
            <p>We believe that Tezos has a vibrant artist community.
                We have set a goal to make an resource where you can find out who is who in the world of Tezos
                NFTs.</p>
            <p>The questions that we aim to answer are:
                <ul>
                    <li>Who are the most valued NFT artists on Tezos?</li>
                    <li>Who are the biggest collectors of NFTs on Tezos?</li>
                    <li>Who my favorite artist collects?</li>
                </ul>
            </p>
            <p>
                In the last few weeks we have added some nec and cool features that allow you to dive deep into the
                artist's transaction history. Go to an artist's profile (take a random artis
                like <a href={'/profile/zancan.tez'}>zancan</a>) and click on any date in the artist's value chart. You
                can also select a date from the date picker in the tabs. You will be able to see all the transactions
                with the artist's tokens on the given day.
            </p>

            <p>We have also added a page for each token's all time transaction history. Click on a token's thumbnail in
                any of the tables to see something
                like <a href={'/history/KT1MEEn4SZ7fD5c4FKXf68hZoWjtwcf4EGLg/27'}> this</a></p>

            <p>The work is in progress and we add new things every day bit by bit. I would very much appreciate your
                input. Feel free to share your ideas for new features, point our errors or just say hi on
                Twitter/X: <a href="https://twitter.com/BanzaiTokyo">@BanzaiTokyo</a>

            </p>
        </main>
    );


}

export default About;