// IncomeExpensesTable.tsx
import React, {useEffect, useState} from 'react';
import MaterialReactTable, {MRT_ColumnDef} from 'material-react-table';
import {apiBaseUrl} from "../../../Constants";
import {formatTez, secureFetch} from "../../common/utils";
import {DayTotals} from "../../../models/DayTotals";
import {useParams} from "react-router-dom";
import dayjs from "dayjs";
import ArtistDailySales from "./ArtistDailySales";
import {Alert, Box} from "@mui/material";
import {useAuth} from "../../../AuthContext";

const IncomeExpensesTable = () => {
    const {userId} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<DayTotals[]>([]);
    const { me } = useAuth();

    useEffect(() => {
        setIsLoading(true);

        secureFetch(`${apiBaseUrl}${userId}/money-balance`)
            .then(response => response.json())
            .then((response: DayTotals[]) => {
                setData(response.map((item) => {
                    return {...item, timestamp: dayjs(item.timestamp)}
                }));
                setIsLoading(false);
            });
    }, [userId]);

    const columns: MRT_ColumnDef<DayTotals>[] = [
        {
            id: 'year', accessorFn: (row) => row.timestamp.year(),
            header: 'Year / Month',
            GroupedCell: ({row}) =>
                <Box sx={{color: 'primary.main'}}>
                    <strong>{row.original.timestamp.year()}</strong>
                </Box>,
            size: 50
        },
        {
            id: 'month',
            accessorFn: (row) => row.timestamp.month(),
            header: '',
            enableSorting: false,
            enableColumnFilter: false,
            enableGrouping: false,
            enableHiding: false,
            enableMultiSort: false,
            GroupedCell: ({row}) =>
                <Box sx={{color: 'primary.main'}}>
                    <strong>{row.original.timestamp.format('MMMM')} </strong>
                </Box>,
            size: 50


        },
        {
            id: 'Date',
            accessorFn: (row) => row.timestamp.format('YYYY-MM-DD'),
            Cell: ({cell}) =>
                <Box sx={{color: 'primary.main'}}>
                    <strong>{cell.row.original.timestamp.format('YYYY-MM-DD')}</strong>
                </Box>,

            header: 'Date',
            size: 150
        },
        {
            accessorKey: 'num_ops', header: '# transactions', size: 100,
            aggregationFn: 'sum',
            AggregatedCell: ({cell}) => (
                <>Total: {cell.getValue()}</>
            ),
        },
        {
            accessorKey: 'income', header: 'Earned', size: 100,
            Cell: ({cell}) => formatTez(cell.getValue() as number),
            aggregationFn: 'sum',
            AggregatedCell: ({cell}) => (
                <>Total: {formatTez(cell.getValue() as number)}</>
            ),
        },
        {
            accessorKey: 'expenses', header: 'Spent', size: 100,
            Cell: ({cell}) => formatTez(cell.getValue() as number),
            aggregationFn: 'sum',
            AggregatedCell: ({cell}) => (
                <>Total: {formatTez(cell.getValue() as number)}</>
            ),
        },
    ];

    return <>
        {(!me || me.wallet !== userId) && <Alert color={"info"} sx={{mb: 2}}>
            Only the wallet owner can see full transactions history. For others the data is limited to last 7 days.
            {!me && " Login, if you are the owner."}
        </Alert>}
        <MaterialReactTable
            columns={columns}
            data={data}
            enableGrouping
            enablePagination={false}
            initialState={{grouping: ['year', 'month'], density: 'compact'}}
            state={{
                isLoading,
                showProgressBars: isLoading,
                sorting: [{id: "year", desc: true}]
            }}
            enableExpandAll={false}
            enableColumnActions={false}
            enableColumnDragging={false}
            manualSorting={false}
            enableGlobalFilter={false}
            positionGlobalFilter={'none'}
            enableToolbarInternalActions={false}
            enableTopToolbar={false}
            renderDetailPanel={({row}) => <ArtistDailySales userId={userId!} day={row.original.timestamp} opsFilter={"salesonly"} />}
        />
    </>;
};

export default IncomeExpensesTable;
