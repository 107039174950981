export const PROFILE_PATH = 'profile';
export const TOP_ARTISTS_PATH = '/top-artists';
export const TOP_COLLECTORS_PATH = '/top-collectors';
export const HISTORY_PATH = '/history';


export type Order = 'asc' | 'desc';


export const SITE_NAME = 'BigOnTezos.com';


export const DESCRIPTION_PREVIEW_LENGTH = 70;

export type MaterialColor = "success" | "error" | "secondary" | "primary" | "inherit" | "grey" | "info" | "warning" | undefined;
export type ChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | undefined
