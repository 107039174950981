import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import {HISTORY_PATH, PROFILE_PATH} from "./features/common/Constants";
import ProfilePage from "./features/profile/ProfilePage";
import Layout from "./features/common/components/Layout";
import About from "./features/about/About";
import Home from "./features/home/components/Home";
import TokenPage from "./features/token/TokenPage";
import Reports from "./features/profile/reports/Reports";
import { AuthProvider } from './AuthContext';

function App() {
    return (
        <AuthProvider>
        <BrowserRouter>
            <Routes>

                <Route element={<Layout/>}>
                    <Route index element={<Navigate to="/tab/artists" replace/>}/>
                    <Route path={'/tab/:tabName'} element={<Home/>}/>

                    <Route path="about" element={<About/>}/>

                    {/*legacy path should be removed in the future*/}
                    <Route path={`/user/:userId`} element={<ProfilePage/>}/>
                    <Route path={`${HISTORY_PATH}/:collectionId/:tokenId`} element={<TokenPage/>}/>

                    <Route path={`${PROFILE_PATH}/:userId`} element={<ProfilePage/>}/>

                    <Route path={`reports/:userId`} element={<Reports/>}/>
                    <Route path={PROFILE_PATH} // if no user id -> redirects to home
                           element={<Navigate to="/" replace />}/>

                    <Route
                        path="*"
                        element={<Navigate to="/" replace />}
                    />



                    {/*<Route*/}
                    {/*    path={TOP_ARTISTS_PATH}*/}
                    {/*    element={<ArtistList/>}*/}
                    {/*/>*/}
                </Route>
            </Routes>
        </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
