import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams,} from 'react-router-dom';
import {Grid} from "@mui/material";
import ProfileInfo from "./ProfileInfo";
import ProfileTabs from "./ProfileTabs";
import {Profile} from "../../models/Profile";
import {apiBaseUrl} from "../../Constants";
import ArtistsValueChart from "./ArtistsValueChart";
import dayjs, {Dayjs} from "dayjs";


function ProfilePage() {
    const {userId} = useParams();
    const [profile, setProfile] = useState<Profile>();
    const [selectedDay, setSelectedDay] = useState<Dayjs | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const selectedDayParam = searchParams.get('selectedDay');

    useEffect(function convertSelectedDay() {
        if (selectedDayParam === 'yesterday') {
            setSelectedDay(dayjs((new Date()).setDate((new Date()).getDate() - 1)));
        } else if (selectedDayParam != null) {
            setSelectedDay(dayjs(selectedDayParam));
        } else setSelectedDay(null);
    }, []);

    const onSelectedDayChange = (newDay: Dayjs | null) => {
        if (newDay != null && !newDay.isSame(selectedDay, 'date')) {
            searchParams.set('selectedDay', newDay.format('YYYY-MM-DD'));
            searchParams.delete('tab');
        } else {
            searchParams.delete('selectedDay');
        }
        setSearchParams(searchParams);
        setSelectedDay(newDay);
    }

    useEffect(() => {
        if (userId != null) {
            fetch(`${apiBaseUrl}${userId}/profile`)
                .then(response => response.json())
                .then((user: Profile) => setProfile(user))
        }
    }, [userId]);


    return (
        <div>
            <Grid container spacing={1} alignItems="stretch">
                <Grid item sm={12} md={4}>
                    <ProfileInfo userId={userId} profile={profile}/>
                </Grid>
                {/*TODO: remove the left padding for the chart on mobile*/}
                <Grid item sm={12} md={8}>
                    <ArtistsValueChart wallet={profile?.wallet}
                                       proportionDesktop={1.8}
                                       selectedDay={selectedDay != null ? selectedDay : undefined}
                                       onSelectedDayChange={onSelectedDayChange}/>

                </Grid>

                <Grid item xs={12}>
                    <div style={{height: 10}}/>
                    <ProfileTabs userId={userId}
                                 profile={profile} selectedDay={selectedDay}
                                 onSelectedDayChange={onSelectedDayChange}/>
                </Grid>
            </Grid>
        </div>

    );
}

export default ProfilePage;