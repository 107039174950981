import React, {useEffect, useState} from "react";

import {LinearProgress} from '@mui/material';
import {apiBaseUrl} from "../../../Constants";
import {Brush, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Legend, Tooltip, BarChart, Bar} from "recharts";
import {blue, green, grey, orange} from "@mui/material/colors";
import dayjs from "dayjs";


const formatNumber = (value: any) => {
    if (value === 0) return '0';
    return (value / 1E+9).toFixed(2).replace(/\.00$/, '') + ' M';
};

const MarketVolumes = () => {
    const [historyData, setHistoryData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const today = (new Date()).toISOString().slice(0, 10);
        const monthAgo = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
        setIsLoading(true);
        fetch(`${apiBaseUrl}markets/${monthAgo}/${today}`)
            .then(response => response.json())
            .then(history => {
                setHistoryData(history);
                setIsLoading(false);
            });
    }, [])

    if (isLoading) return (<div style={{
        position: 'relative',
        width: '100%',
        paddingBottom: '250px'
    }}>
        {/*this is a hack to make it responsive*/}
        <div
            style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,

            }}
        ><LinearProgress/>
        </div>
    </div>)

    return (
        <ResponsiveContainer height={500}>
            <BarChart
                data={historyData}
                margin={{
                    top: 5,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="date" angle={-90} height={70} tickMargin={30} tickFormatter={(value) => dayjs(value).format('MMM D')}/>
                <YAxis tickFormatter={formatNumber} />
                <Legend layout="vertical" align="right" wrapperStyle={{paddingLeft: 10, paddingBottom: 95}} />
                <Tooltip formatter={formatNumber} shared={true} contentStyle={{backgroundColor: 'black'}} labelStyle={{color:'white'}} labelFormatter={label => dayjs(label).format('MMM D, YYYY')}/>
                <Bar dataKey="fxhash" stackId='1' fill={blue.A700}/>
                <Bar dataKey="objkt" stackId='1' fill={green.A700}/>
                <Bar dataKey="teia" stackId='1' fill={orange.A200}/>
                <Bar dataKey="versum" stackId='1' fill={grey.A700}/>
                <Brush dataKey="date" height={30} stroke={blue["700"]} startIndex={historyData.length-30}/>
            </BarChart>
        </ResponsiveContainer>
    );};

export default MarketVolumes;
