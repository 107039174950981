import {BasicProfile} from "../../models/Profile";

export function cutTheMiddle(str: string) {
    if (str != null) {
        return str.substr(0, 5) + '...' + str.substr(str.length - 5, str.length);
    }
    return str;
}

export function formatTez(amount: number): string {
    const inTez = Math.abs(amount / 1000000);
    if (amount === 0) {
        return String(0);
    }

    const fractionDigits = inTez < 5 ? 2 : 0;
    return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits
    }).format(inTez);
}

export function getDomainProfileOrWallet(profile: BasicProfile) {
    return profile.tezos_domain || profile.tezos_profile_name || cutTheMiddle(profile.wallet)
}

function updateOptions(options: any) {
  const update = { ...options };
  update.credentials = "include";
  return update;
}

export function secureFetch(url: string, options = {}) {
  return fetch(url, updateOptions(options));
}