import React, {useEffect, useMemo, useState} from "react";

import {Box} from '@mui/material';
import MaterialReactTable, {MRT_ColumnDef} from "material-react-table";
import {useSearchParams} from "react-router-dom";
import {apiBaseUrl} from "../../../Constants";
import dayjs, {Dayjs} from "dayjs";
import {formatTez, getDomainProfileOrWallet} from "../../common/utils";
import {PROFILE_PATH} from "../../common/Constants";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DailyOperation} from "../../../models/Operation";
import TopMarketSalesChart from "../../home/components/TopMarketSalesChart";


const TopSalesByDay = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const selectedDayParam = searchParams.get('selectedDay');
    const [selectedDay, setSelectedDay] = useState<Dayjs | null>((selectedDayParam != null && dayjs(selectedDayParam)) || dayjs((new Date()).setDate((new Date()).getDate() - 1)));

    const [dailyOperations, setDailyOperations] = useState<DailyOperation[]>([]);

    useEffect(() => {
        setIsLoading(true);
        selectedDay != null && fetch(`${apiBaseUrl}top/${selectedDay.format('YYYY-MM-DD')}`)
            .then(response => response.json())
            .then((response: DailyOperation[]) => {
                setDailyOperations(response);
                setIsLoading(false);
            });
    }, [selectedDay]);

    const columns = useMemo<MRT_ColumnDef<DailyOperation>[]>(
        () => [
            {
                id: 'image', //id is still required when using accessorFn instead of accessorKey
                header: 'Token',
                size: 50,
                Cell: ({renderedCellValue, row}) => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <a href={`/history/${row.original.collection}/${row.original.token}`}>
                                <img
                                    alt="avatar"
                                    height={50}
                                    src={row.original.thumbnail_url}
                                    loading="lazy"
                                /></a> &nbsp;
                            <a href={`/history/${row.original.collection}/${row.original.token}`}>{row.original.title}</a>
                            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                            <span>{renderedCellValue}</span>
                        </Box>
                    )
                },
            },

            {
                id: 'artists',
                enableClickToCopy: false,
                header: 'Artist',
                enableColumnFilter: false,
                size: 60,
                Cell: ({row}) =>
                    row.original.artists ? row.original.artists.map(artist =>
                        <Box
                            key={row.original.collection + row.original.token + artist.wallet}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                            }}
                        > <a href={`/${PROFILE_PATH}/${artist.wallet}`}>
                            <img
                                alt="avatar"
                                height={50}
                                width={50}
                                src={artist.thumbnail_url}
                                loading="lazy"
                                style={{borderRadius: '50%'}}
                            />
                        </a>

                            <a href={`/${PROFILE_PATH}/${artist.wallet}`}>
                                {getDomainProfileOrWallet(artist)}
                            </a>
                            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                        </Box>) : <></>

            },
            {
                id: 'seller',
                accessorFn: (row) => row.seller.tezos_domain,
                enableClickToCopy: false,
                header: 'Seller',
                enableColumnFilter: false,
                size: 70,
                Cell: ({row}) =>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    > <a href={`/${PROFILE_PATH}/${row.original.seller.wallet}`}>
                        <img
                            alt="avatar"
                            height={50}
                            width={50}
                            src={row.original.seller.thumbnail_url}
                            loading="lazy"
                            style={{borderRadius: '50%'}}
                        />
                    </a>
                        <a href={`/${PROFILE_PATH}/${row.original.seller.wallet}`}>{getDomainProfileOrWallet(row.original.seller)}</a>
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                    </Box>

            },
            {
                id: 'buyer',
                accessorFn: (row) => row.buyer.tezos_domain,
                enableClickToCopy: false,
                header: 'Buyer',
                enableColumnFilter: false,
                size: 100,
                Cell: ({row}) =>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <a href={`/${PROFILE_PATH}/${row.original.buyer.wallet}`}><img
                            alt="avatar"
                            height={50}
                            width={50}
                            src={row.original.buyer.thumbnail_url}
                            loading="lazy"
                            style={{borderRadius: '50%'}}
                        />
                        </a>

                        <a href={`/${PROFILE_PATH}/${row.original.buyer.wallet}`}>{getDomainProfileOrWallet(row.original.buyer)}</a>
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                    </Box>

            },
            {
                id: 'value',
                accessorFn: (row) => formatTez(row.value!),
                enableClickToCopy: false,
                header: 'Value',
                enableColumnFilter: false,
                enableMultiSort: false,
                size: 30,
            },
        ],
        [],
    );

    const onSelectedDayChange = (newDay: Dayjs | null) => {
        if (newDay != null && !newDay.isSame(selectedDay, 'date')) {
            searchParams.set('selectedDay', newDay.format('YYYY-MM-DD'));
        } else {
            searchParams.delete('selectedDay');
        }
        setSearchParams(searchParams);
        setSelectedDay(newDay);
    }

    return <>
        <Box>
            <TopMarketSalesChart
                proportionDesktop={1.8}
                selectedDay={selectedDay} onSelectedDayChange={onSelectedDayChange}
            />
        </Box>
        <Box sx={{borderBottom: 1, borderColor: 'divider', paddingBottom: '1rem', paddingTop:'1rem'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    label="Transactions on:"
                    value={selectedDay}
                    onChange={onSelectedDayChange}
                    format="DD, MMMM YYYY"
                    maxDate={dayjs((new Date()).setDate((new Date()).getDate() - 1))}
                    minDate={dayjs((new Date()).setFullYear((new Date()).getFullYear() - 1))}
                    views={['day']}
                />
            </LocalizationProvider>
        </Box>

        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <MaterialReactTable
                enablePagination={false}
                columns={columns}
                data={dailyOperations}
                positionToolbarAlertBanner="bottom"
                enableColumnActions={false}
                enableTopToolbar={false}

                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}

                manualFiltering
                enableDensityToggle={false}
                initialState={{density: 'compact'}}
                state={{
                    isLoading,
                    showProgressBars: isLoading,
                }}

                manualSorting

            />
        </Box></>;
};

export default TopSalesByDay;


