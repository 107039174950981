import React, {useEffect, useMemo, useRef, useState} from "react";

import {Box} from '@mui/material';
import MaterialReactTable, {MRT_ColumnDef, MRT_ColumnFiltersState, MRT_SortingState} from "material-react-table";
import {useSearchParams} from "react-router-dom";
import {apiBaseUrl} from "../../../Constants";
import {ApiResponse} from "../../../models/ApiResponse";
import {Dayjs} from "dayjs";
import {DailyOperation} from "../../../models/Operation";
import {formatTez, getDomainProfileOrWallet} from "../../common/utils";
import {ColumnFilter, Updater} from "@tanstack/table-core";
import {PROFILE_PATH} from "../../common/Constants";

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const ArtistDailySales = (props: { userId: string, day: Dayjs, opsFilter?: string }) => {
    const isMount = useIsMount();
    const {userId, day, opsFilter = 'all'} = props;
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);

    const [totalRecords, setTotalRecords] = useState(100);
    const [operationFilter, _setOperationFilter] = useState(opsFilter);

    const rowsPerPage = 10;
    const [dailyOperations, setDailyOperations] = useState<DailyOperation[]>([]);

    const orderBy = searchParams.get('order_by') || '-sale_amount'
    const [sorting, setSorting] = useState<MRT_SortingState>([{
        id: orderBy.replace(/^-/g, ''),
        desc: orderBy.substring(0, 1) === '-'
    }]);
    const page = parseInt(searchParams.get('page') || '1', 10);
    const [pagination, setPagination] = useState({
        pageIndex: page - 1,
        pageSize: rowsPerPage,
    });
    // if search parameter "search is not empty use it to set initial state of the table's filter (search)
    const search = searchParams.get('search');
    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(search ? [{
        "id": "title",
        "value": search
    }] : []);

    let titleFilter = columnFilters.find((fil) => fil.id === 'title');
    let titleFilterString = titleFilter != null ? String(titleFilter.value) : "";

    useEffect(() => {
        if (pagination.pageIndex > 0) {
            searchParams.set("page", String(pagination.pageIndex + 1));
        } else {
            searchParams.delete("page");
        }
        if (titleFilterString != null && titleFilterString.length > 1) {
            searchParams.set('search', titleFilterString)
        } else {
            searchParams.delete('search')
        }
        if (sorting.length) {
            searchParams.set('order_by', (sorting[0].desc ? `-${sorting[0].id}` : sorting[0].id));
        } else {
            searchParams.delete('order_by')
        }
        setSearchParams(searchParams);
    }, [pagination.pageIndex, titleFilterString, sorting]);

    const loadTableData = () => {
        const queryString: Record<string, any> = {
            offset: pagination.pageIndex * rowsPerPage
        };
        if (titleFilterString) {
            queryString.search = titleFilterString
        }
        if (sorting.length) {
            queryString.order_by = sorting[0].desc ? `-${sorting[0].id}` : sorting[0].id;
        }

        setIsLoading(true);
        fetch(`${apiBaseUrl}${userId}/daily/${day.format('YYYY-MM-DD')}/${operationFilter}?${new URLSearchParams(queryString).toString()}`)
            .then(response => response.json())
            .then((response: ApiResponse<DailyOperation>) => {
                setTotalRecords(response.meta.totalRowCount);
                setDailyOperations(response.data);
                setIsLoading(false);
            });
    }
    useEffect(() => {
        loadTableData()
    }, [titleFilterString, sorting, userId, pagination.pageIndex, operationFilter]);

    // I found no way to change the day and reset pagination simultaneously, so decided to use 2 useEffects.
    // Plus it needs to distinguish state change from application reload with some query string
    useEffect(() => {
        if (!isMount) {
            if (pagination.pageIndex) {
                setPagination({...pagination, pageIndex: 0})
            } else {
                loadTableData()
            }
        }
    }, [day])

    const columns = useMemo<MRT_ColumnDef<DailyOperation>[]>(
        () => [
            {
                id: 'image', //id is still required when using accessorFn instead of accessorKey
                header: 'Image',
                size: 50,
                Cell: ({renderedCellValue, row}) => {
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <a href={`/history/${row.original.collection}/${row.original.token}`}>
                                <img
                                    alt="avatar"
                                    height={50}
                                    src={row.original.thumbnail_url}
                                    loading="lazy"
                                /></a>
                            {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                            <span>{renderedCellValue}</span>
                        </Box>
                    )
                },
            },

            {
                id: 'title',
                accessorFn: (row) => row.title ? row.title : '',
                enableClickToCopy: false,
                header: 'Title',
                enableColumnFilter: false,
                sortDescFirst: true,
                enableMultiSort: false,
                size: 100,
            },
            {
                id: 'seller',
                accessorFn: (row) => row.seller.tezos_domain,
                enableClickToCopy: false,
                header: 'Seller',
                enableColumnFilter: false,
                size: 70,
                Cell: ({row}) =>
                    <>{row.original.seller.wallet !== userId && <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    > <img
                        alt="avatar"
                        height={50}
                        width={50}
                        src={row.original.seller.thumbnail_url}
                        loading="lazy"
                        style={{borderRadius: '50%'}}
                    />
                        <a href={`/${PROFILE_PATH}/${row.original.seller.wallet}`}>{getDomainProfileOrWallet(row.original.seller)}</a>
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                    </Box>}</>

            },
            {
                id: 'buyer',
                accessorFn: (row) => row.buyer.tezos_domain,
                enableClickToCopy: false,
                header: 'Buyer',
                enableColumnFilter: false,
                size: 100,
                Cell: ({row}) =>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    > <img
                        alt="avatar"
                        height={50}
                        width={50}
                        src={row.original.buyer.thumbnail_url}
                        loading="lazy"
                        style={{borderRadius: '50%'}}
                    />
                        <a href={`/${PROFILE_PATH}/${row.original.buyer.wallet}`}>{getDomainProfileOrWallet(row.original.buyer)}</a>
                        {/* using renderedCellValue instead of cell.getValue() preserves filter match highlighting */}
                    </Box>

            },
            {
                id: 'sale_amount',
                accessorFn: (row) => formatTez(row.sale_amount!),
                enableClickToCopy: false,
                header: 'Amount',
                enableColumnFilter: false,
                enableMultiSort: false,
                size: 30,
            },
            {
                id: 'royalties_amount',
                accessorFn: (row) => formatTez(row.royalties_amount!),
                enableClickToCopy: false,
                header: 'Royalties',
                enableColumnFilter: false,
                enableMultiSort: false,
                size: 30,
            },
            {
                id: 'profits_amount',
                accessorFn: (row) => formatTez(row.profits_amount!),
                enableClickToCopy: false,
                header: 'Earnings',
                enableColumnFilter: false,
                enableMultiSort: false,
                size: 30,
            },
        ],
        [],
    );

    const updateColumnFilters = (filters: Updater<ColumnFilter[]>) => {
        setColumnFilters(filters);

        if (titleFilterString) {
            setPagination({
                pageIndex: 0,
                pageSize: rowsPerPage,
            });
        }
    }

    return <>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>

            <MaterialReactTable
                columns={columns}
                data={dailyOperations}
                positionToolbarAlertBanner="bottom"
                enableColumnActions={false}
                enableTopToolbar={false}

                manualPagination
                rowCount={totalRecords}

                muiTablePaginationProps={{
                    rowsPerPageOptions: [rowsPerPage],
                    showFirstButton: pagination.pageIndex > 0,
                    showLastButton: totalRecords / rowsPerPage > 1,
                    page: pagination.pageIndex
                }}
                onPaginationChange={setPagination}


                muiTableProps={{
                    sx: {
                        tableLayout: 'fixed',
                    },
                }}

                manualFiltering
                onColumnFiltersChange={updateColumnFilters}
                enableDensityToggle={false}
                initialState={{density: 'compact'}}
                state={{
                    pagination,
                    isLoading,
                    showProgressBars: isLoading,
                    showColumnFilters: true,
                    sorting,
                    columnFilters
                }}

                manualSorting
                onSortingChange={setSorting}

            />
        </Box></>;
};

export default ArtistDailySales;


