import {Alert, Collapse, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect, useState} from "react";


export default function IntroMessage() {
    const [isIntroMessageSeen, setIsIntroMessageSeen] = useState(true);

    useEffect(() => {
        setIsIntroMessageSeen(Boolean(window.localStorage.getItem('isIntroMessageSeen')));
    }, [isIntroMessageSeen]);

    function onCloseIntroMessageClicked() {
        window.localStorage.setItem('isIntroMessageSeen', 'true');
        setIsIntroMessageSeen(true);
    }

    if (isIntroMessageSeen) return null;

    return <Collapse in={!isIntroMessageSeen}>
        <Alert
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={onCloseIntroMessageClicked}
                >
                    <CloseIcon fontSize="inherit"/>
                </IconButton>
            }
            sx={{mb: 2}}
        >
            BigOnTezos constantly adds new features. What to know when the feature you need rolls out? Want
            to share a comment? Drop me a line at <a
            href={"https://twitter.com/banzaitokyo"}>@BanzaiTokyo</a>
        </Alert>
    </Collapse>;
}
