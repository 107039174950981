import React from 'react';
import {Button, IconButton, Link} from '@mui/material';
import {TezosToolkit} from "@taquito/taquito";
import {BeaconWallet} from '@taquito/beacon-wallet';
import {char2Bytes} from '@taquito/utils';
import {RequestSignPayloadInput, SigningType, SignPayloadResponseOutput} from '@airgap/beacon-types';
import {SITE_NAME} from '../common/Constants';
import {apiBaseUrl} from "../../Constants";
import {useAuth} from "../../AuthContext";
import {cutTheMiddle, secureFetch} from "../common/utils";
import LogoutIcon from '@mui/icons-material/Logout';

const WalletAuth: React.FC = () => {
    const {me, login, logout} = useAuth();

    const connectWallet = async () => {
        let myAddress: string;
        const Tezos = new TezosToolkit("https://mainnet.smartpy.io");
        const wallet = new BeaconWallet({name: SITE_NAME});
        let account;

        Tezos.setWalletProvider(wallet);
        try {
            account = await wallet.client.getActiveAccount();
        } catch (error) {
            console.error('Error getting active account:', error);
        }
        if (account) {
            myAddress = account.address;
            console.log("Already connected:", account.address);
        } else {
            await wallet.requestPermissions();
            myAddress = await wallet.getPKH();
            console.log("New connection:", myAddress);
        }

        const formattedInput: string = [
            'I want to login on',
            SITE_NAME,
            'at',
            new Date().toISOString(),
        ].join(' ');
        const bytes = char2Bytes(formattedInput);
        const bytesLength = (bytes.length / 2).toString(16);
        const addPadding = `00000000${bytesLength}`;
        const paddedBytesLength = addPadding.slice(addPadding.length - 8);
        const payloadBytes = '05' + '01' + paddedBytesLength + bytes;
        const payload: RequestSignPayloadInput = {
            signingType: SigningType.MICHELINE,
            payload: payloadBytes,
            sourceAddress: myAddress,
        };

        try {
            wallet.client.requestSignPayload(payload).then((signedPayload: SignPayloadResponseOutput) => {
                const {signature} = signedPayload;
                const postData = {
                    wallet: myAddress,
                    message: payloadBytes,
                    signature
                };
                return secureFetch(`${apiBaseUrl}login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(postData),
                })
            }).then(async (response: Response) => {
                if (response.ok) {
                    const profile = await response.json();
                    login(profile);
                } else {
                    const result = await response.text();
                    console.error(result);
                }
            });
        } catch (error) {
            console.error('Error connecting wallet:', error);
        }
    };

    return me ?
        <>
            &nbsp;
            <Button>
                <Link href={`/reports/${me.wallet}`}
                      style={{color: 'white'}}
                      underline="none">
                    {me.tezos_profile_name || me.tezos_domain || cutTheMiddle(me.wallet)}
                </Link>
            </Button>
            &nbsp;

            <IconButton onClick={logout}><LogoutIcon/></IconButton>
        </>
        :
        <Button variant="contained" onClick={connectWallet}>Connect</Button>
};

export default WalletAuth;
