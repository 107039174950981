import React from 'react';
import {TokenOperation, TradeType} from "../../models/Operation";
import {ChipColor, MaterialColor, PROFILE_PATH} from "../common/Constants";
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {Chip} from "@mui/material";
import {cutTheMiddle, formatTez} from "../common/utils";


interface HistoryOperationProps {
    operation: TokenOperation;
    isLastItem: boolean;
}

interface ColorMap {
    [key: string]: MaterialColor;
}

interface ChipColorMap {
    [key: string]: ChipColor;
}

const opColors: ColorMap = {
    "mint": "success",
    "burn": "error",
    "transfer": "secondary",
    "buy": "primary",
    "buy_teia": "primary",
    "buy_fxhash_v2": "primary",
    "buy_fxhash_v1": "primary",
    "english_auction_v4": "primary",
    "offer": "primary",
    "list": "grey",
};

const chipColors: ChipColorMap = {
    "mint": "success",
    "burn": "error",
    "transfer": "secondary",
    "buy": "primary",
    "buy_teia": "primary",
    "buy_fxhash_v2": "primary",
    "buy_fxhash_v1": "primary",
    "english_auction_v4": "primary",
    "offer": "primary",
    "list": "default"
};

function getOperatuinTypeLabel(tradetype: TradeType) {
    if (tradetype === 'buy') return 'sell';
    if (tradetype === 'offer') return 'accepted offer';
    return tradetype;
}

function HistoryOperation(props: HistoryOperationProps) {

    let {operation, isLastItem} = props;

    const operationColor: MaterialColor = opColors[operation.tradetype];
    const chipColor: ChipColor = chipColors[operation.tradetype];

    return (
        <TimelineItem key={operation.seller.wallet + operation.buyer.wallet}>
            <TimelineOppositeContent color="textSecondary">
                {`${new Date(operation.timestamp).toLocaleDateString()}`}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color={operationColor}/>
                {!isLastItem && <TimelineConnector/>}
            </TimelineSeparator>
            <TimelineContent>

                <Chip label={getOperatuinTypeLabel(operation.tradetype)}
                      color={chipColor}
                      variant='outlined'
                      size='small'
                      sx={{minWidth: '8rem'}}/>

                &nbsp;

                {operation.price > 0 && <Chip label={formatTez(operation.price) + ' TEZ'}
                                              variant='filled'
                                              size='small'
                                              sx={{minWidth: '5rem'}}
                />}

                &nbsp;

                <a href={`/${PROFILE_PATH}/${operation.seller.wallet}`}>
                    {operation.seller.tezos_domain ? operation.seller.tezos_domain :
                        operation.seller.tezos_profile_name != null && operation.seller.tezos_profile_name !== operation.seller.wallet ? operation.seller.tezos_profile_name : cutTheMiddle(operation.seller.wallet)}</a>

                {operation.tradetype !== 'mint' && <span> &nbsp;=&gt;&nbsp;
                    <a href={`/${PROFILE_PATH}/${operation.buyer.wallet}`}>
                                    {operation.buyer.tezos_domain ? operation.buyer.tezos_domain :
                                        (operation.buyer.tezos_profile_name && operation.buyer.tezos_profile_name !== operation.buyer.wallet)
                                            ? operation.buyer.tezos_profile_name
                                            : cutTheMiddle(operation.buyer.wallet)
                                    } </a>
                            </span>
                }
            </TimelineContent>
        </TimelineItem>

    );
}

export default HistoryOperation;