import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import {BasicProfile} from "./models/Profile";
import {secureFetch} from "./features/common/utils";
import {apiBaseUrl} from "./Constants";

type AuthContextType = {
  me: BasicProfile | null;
  login: (profile: BasicProfile) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [me, setMe] = useState<BasicProfile | null>(null);

  useEffect(() => {
      const loadProfile = async () => {
        let profile = await secureFetch(`${apiBaseUrl}me`).then((response) => {
          if (response.ok) return response.json()
        });
        login(profile);
      };
      loadProfile()
  }, []);

  const login = (profile: BasicProfile) => {
    setMe(profile)
  };

  const logout = () => {
    const doLogout = async () => {
      await secureFetch(`${apiBaseUrl}logout`);
      setMe(null);
    };
    doLogout()
  };

  const contextValue: AuthContextType = { me, login, logout };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
